<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-8">
      <span class="text-h5"><strong> Re-Assign Donor Information</strong></span>
      <!-- <v-btn color="primary" @click="addDonorModal = true"> Add New Donor</v-btn> -->
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.donor_name" label="Donor's Name" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.phone_no" label="Phone No." outlined dense hide-details counter="10"
          @keypress="onlyNumber"></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filter.received_on"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="filter.received_on" label="Received On"  readonly outlined
              dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="filter.received_on" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(filter.received_on)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.referral_name" label="Referral By" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="filter.referral_contact_number" label="Referral Contact No" outlined dense hide-details
          counter="10" @keypress="onlyNumber"></v-text-field>
      </v-col>
      
      <v-col>
      <v-text-field v-model="filter.lab_technician_nameFilter" label="Lab Technician Name" outlined dense hide-details></v-text-field>
    </v-col>
      <!-- lab_technician_name -->
      <!-- <v-col cols="12" md="3">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :return-value.sync="filter.date_of_time_of_excision"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.date_of_time_of_excision"
              label="Date of Excision"
              prepend-icon="mdi-calendar"
              readonly
              outlined
              dense
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_of_time_of_excision" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu2.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu2 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu2.save(filter.date_of_time_of_excision)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col> -->
      <v-col cols="12" md="6" class="pl-0">
        <v-btn color="primary" class="mr-2" @click="DonorSearchList()"> Search</v-btn>
        <v-btn color="primary" @click="getDonorRefresh()">Reset</v-btn>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :loading="listLoading" :items="donorList" :items-per-page="10" item-key='subId'
      class="table-rounded" fixed-header height="400" :single-expand="singleExpand" :expanded.sync="expanded"
      show-expand>

      <template #[`item.previous_assign_date`]="{ item }">
        <pre>{{ previousAssignDate(item.previous_assign_date) }}</pre>
      </template>

      <template #[`item.assign_lab_tech_date`]="{ item }">
        <pre>{{ assignLabTechDate(item.assign_lab_tech_date) }}</pre>
      </template>

      <!-- <template #[`item.operate`]="{ item }" style="display:flex">
        <v-btn color="primary" class="mr-3" :disabled="item.donor_information_form_fill === 0"
          @click="editInformation(item)"> Edit </v-btn>
        <EditDonorInformation :visible="editDonorinformation" :item="editItem" :method="getdonorList"
          @close="editDonorinformation = false" />
        <v-autocomplete v-if="usertype == 'Lab Technician - Head' || usertype == 'Admin' || usertype == 'Manager'" style="width:150px; display:inline-block"
          @change="formRoute(item)" outlined dense v-model="item.assign_lab_technician_name" item-text="name"
          hide-details :items="Allforma" label="Assign">
        </v-autocomplete>
        <v-btn color="error" text @click="deleteDonorInformations(item)"> Delete </v-btn>
      </template> -->

      <!-- <template #[`item.Action`]="{ item }" style="display:flex">
        <v-autocomplete v-if="item.form_a_show_hide == 0 && item.form_b_show_hide == 0"
          style="width:150px; display:inline-block" @input="handleForms" @change="formsRoute(item)" outlined dense
          hide-details :items="Allform" label="Select Form"> </v-autocomplete>
        <v-autocomplete v-if="item.form_a_show_hide == 1 && item.form_b_show_hide == 0"
          style="width:150px; display:inline-block" @input="handleForms" @change="formsRoute(item)" outlined dense
          hide-details :items="Allform1" label="Select Form"> </v-autocomplete>
        <v-autocomplete v-if="item.form_a_show_hide == 0 && item.form_b_show_hide == 1"
          style="width:150px; display:inline-block" @input="handleForms" @change="formsRoute(item)" outlined dense
          hide-details :items="Allform2" label="Select Form"> </v-autocomplete>
      </template> -->

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <p class="justify-center d-flex"><strong> Progress Bar </strong></p>
          <v-stepper v-model="item.progress_count" alt-labels class="ml-n11">
            <v-stepper-header>
              <v-stepper-step :complete="item.progress_count > 1" step="1">
                <div class="text-center">Notification<br />Form</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 2" step="2">
                <div class="text-center">Form A</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 3" step="3">
                <div class="text-center">Form B</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 4" step="4">
                <div class="text-center">Form C<br />Laboratory<br /> Information</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 5" step="5">
                <div class="text-center">Donor<br />Information</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 6" step="6">
                <div class="text-center">Eye <br /> Donor<br />Particulars</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 7" step="7">
                <div class="text-center">Form C</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 8" step="8">
                <div class="text-center">Hemo<br />dilution<br /> Form</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 9" step="9">
                <div class="text-center">Tissue<br />Evaluation</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 10" step="10">
                <div class="text-center">Tissue<br />Detail</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 11" step="11">
                <div class="text-center">Tissue<br />Offered</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 12" step="12">
                <div class="text-center">Bill of<br /> Lading</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 13" step="13">
                <div class="text-center">Adverse<br />Reaction</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="item.progress_count > 14" step="14">
                <div class="text-center">Recipient<br />Information</div>
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="15">
                <div class="text-center">Tissue<br />Disposition</div>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>

          <v-row class="pt-3 col-12">
            <v-col sm="6" md="6">
              <p><strong> General Information </strong></p>
              <p>Donor's Relative's Name: {{ item.donor_relative_name }}</p>
              <p>Date and Time of Donor's Death: {{ convertDeathDate(item.date_of_time_of_death) }}</p>
              <p>Cause of Donor's Death: {{ item.cause_of_death }}</p> 
              <p>Consignment Recieved on: {{ convertReceivedDate(item.received_on) }}</p>
            </v-col>
            <v-col sm="6" md="6">
              <p><strong> Referral Information</strong></p>
              <p>Referral by: {{ item.referral_name }}</p>
              <p>Referral Phone No: {{ item.referral_contact_number }}</p>
              <p>Requesting Person: {{ item.category_type_of_the_requesting_person }}</p>
            </v-col>
          </v-row>
        </td>
      </template>

    </v-data-table>

    <v-dialog v-model="DeleteModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Delete confirmation </v-card-title>
        <v-card-text> Are you sure you want to delete ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="DeleteModal = false"> Cancel </v-btn>
          <v-btn color="primary" @click="deleteDonorInformation(thisItemId)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="ConfirmModal" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Continue to Assign</v-card-title>
        <v-card-text> Are you sure you want to Assign this Donor? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="ConfirmModal = false"> Cancel </v-btn>
          <v-btn color="primary" @click="confirmTONext(newitem)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog v-model="addDonorModal" max-width="1140">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5"><strong> Add Donor Information</strong></span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="DonorInformationList.donor_name" label="Donor's Name*" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0">Parent/Guardian*:</p>
                <v-radio-group row class="mt-0" v-model="DonorInformationList.s_w_d_of">
                  <v-radio name="Parents_name" label="S" value="Son"></v-radio>
                  <v-radio name="Parents_name" label="W" value="Wife"></v-radio>
                  <v-radio name="Parents_name" label="D" value="Daughter"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="DonorInformationList.donor_parent_guardian_name" label="Parent/Guardian Name*"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="DonorInformationList.age" hide-details label="Donor's Age*" required
                  @keypress="onlyAge" @paste.prevent></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <p class="mb-0">Sex*:</p>
                <v-radio-group row class="mt-0" v-model="DonorInformationList.sex">
                  <v-radio name="sex" label="Male" value="Male"></v-radio>
                  <v-radio name="sex" label="Female" value="Female"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-textarea v-model="DonorInformationList.postal_address" hide-details label="Postal Address*" required
                  rows="2"></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="DonorInformationList.phone_no" hide-details label="Donor's Phone No*" required
                  @paste.prevent @keypress="onlyNumber"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="DonorInformationList.donor_relative_name" hide-details
                  label="Received from (Name of nearest relation)*" required></v-text-field>
              </v-col>
              <!-- <v-col cols="12" sm="6" md="4">
                <v-text-field
                  hide-details
                  v-model="DonorInformationList.received_from"
                  label="Received from (Name of nearest relation)"
                  required
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0">Relative relation with Donor*:</p>
                <v-radio-group row class="mt-0" v-model="DonorInformationList.relation_with_donor">
                  <v-radio name="donor_relation" label="Son" value="Son"></v-radio>
                  <v-radio name="donor_relation" label="Wife" value="Wife"></v-radio>
                  <v-radio name="donor_relation" label="Daughter" value="Daughter"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field hide-details v-model="DonorInformationList.referral_name" label="Referral Name*" required
                  readonly @focusin="toggleReadonly" @focusout="toggleReadonly"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field hide-details label="Referral Contact No*." required
                  v-model="DonorInformationList.referral_contact_number" readonly @focusin="toggleReadonly"
                  @focusout="toggleReadonly" @keypress="onlyNumber"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0">Requesting Person*</p>
                <v-radio-group row class="mt-0" @change="checkEyeBankStaff()"
                  v-model="DonorInformationList.category_type_of_the_requesting_person">
                  <v-radio name="Parents_name" label="Eye Bank Staff" value="Eye Bank Staff"></v-radio>
                  <v-radio name="Parents_name" label="Hospital Staff" value="Hospital Staff"></v-radio>
                  <v-radio name="Parents_name" label="Volunteer" value="Volunteer"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-datetime-picker v-model="deathDate" time-format="HH:mm:ss" label="Date and Time of Death*">
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-datetime-picker v-model="consentDate" time-format="HH:mm:ss" label="Date and Time of Consent*">
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-datetime-picker v-model="excisionDate" time-format="HH:mm:ss" label="Date and Time of Excision*">
                </v-datetime-picker>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="DonorInformationList.cause_of_death" label="Cause of Death*" required>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="DonorInformationList.temperature" label="Temperature*" required
                  @keypress="onlyNumber"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field v-model="DonorInformationList.consignment_information" label="Consignment Information*"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-datetime-picker v-model="receivedDate" time-format="HH:mm:ss" label="Received on*">
                </v-datetime-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="body-1 mb-0 "><strong> INFORMATION ABOUT EYES RECEIVED</strong></p>
              </v-col>
              <v-col cols="12" md="5">
                <v-checkbox class="mt-0" v-model="DonorInformationList.left_eye" label="L.E (OS)*" value="LE"
                  @change="restLE()"></v-checkbox>
                <v-textarea v-if="DonorInformationList.left_eye == 'LE'"
                  v-model="DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_receive"
                  label="Condition of Left Eye Ball/Cornea at time of receipt" rows="3" row-height="15"></v-textarea>
                <v-textarea v-if="DonorInformationList.left_eye == 'LE'"
                  v-model="DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_inspection"
                  label="Condition of Left Eye Ball/Cornea on Inspection" rows="3" row-height="15"></v-textarea>
              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
                <v-checkbox class="mt-0" v-model="DonorInformationList.right_eye" label="R.E (OD)*" value="RE"
                  @change="restRE()"></v-checkbox>
                <v-textarea v-if="DonorInformationList.right_eye == 'RE'"
                  v-model="DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_receive"
                  label="Condition of Right Eye Ball/Cornea at time of receipt" rows="3" row-height="15"></v-textarea>
                <v-textarea v-if="DonorInformationList.right_eye == 'RE'"
                  v-model="DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_inspection"
                  label="Condition of Right Eye Ball/Cornea on Inspection" rows="3" row-height="15"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-textarea v-if="DonorInformationList.left_eye == 'LE' || DonorInformationList.right_eye == 'RE'"
                  v-model="DonorInformationList.any_other_information" label="Any other Information" rows="3"
                  row-height="15"></v-textarea>
              </v-col>
              <v-col cols="12" md="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-row>
                  <v-col cols="12">
                    <p class="mb-0">Excision set</p>
                  </v-col>
                  <v-col cols="12">
                    <v-datetime-picker v-model="excisionSterilizationDate" time-format="HH:mm:ss"
                      label="Excision set date and time of sterilization*"></v-datetime-picker>
                  </v-col>
                  <v-col cols="12">
                    <v-datetime-picker v-model="excisionExpiryDate" time-format="HH:mm:ss"
                      label="Excision set date and time of expiry*"></v-datetime-picker>
                  </v-col>
                  <v-col cols="7">
                    <v-file-input v-model="ExcisionSetInitals" chips accept="image/*" label="Excision set initals*"
                      @change="onFileChangeExcision" />
                  </v-col>
                  <v-col cols="5">
                    <img v-img style="width:100%" :src="url1" />
                  </v-col>
                  <!-- <v-text-field
                  v-model="DonorInformationList.excision_set_initials"
                  label="Excision set initals"
                  required
                ></v-text-field> -->
                </v-row>
              </v-col>
              <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
                <v-row>
                  <v-col cols="12">
                    <p class="mb-0">Linen set </p>
                  </v-col>
                  <v-col cols="12">
                    <v-datetime-picker v-model="linenSterilizationDate" time-format="HH:mm:ss"
                      label="Linen set date and time of sterilization*"></v-datetime-picker>
                  </v-col>
                  <v-col cols="12">
                    <v-datetime-picker v-model="linenExpiryDate" time-format="HH:mm:ss"
                      label="Linen set date and time of expiry*"></v-datetime-picker>
                  </v-col>
                  <v-col cols="7">
                    <v-file-input v-model="LinenSetInitials" chips accept="image/*" label="Linen set initials*"
                      @change="onFileChangeLinen" />
                  </v-col>
                  <v-col cols="5">
                    <img v-img style="width:100%" :src="url" />
                  </v-col>
                  <!-- <v-text-field
                  v-model="DonorInformationList.linen_set_initials"
                  label="Linen set initials"
                  required
                ></v-text-field> -->
                </v-row>
              </v-col>
              <v-col cols="12" md="1"></v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="3" md="3">
                <!-- <v-col cols="4"> -->
                <v-file-input v-model="file" chips accept="image/*" label="Permission Certificate*"
                  @change="onFileChange" />
              </v-col>
              <v-col cols="2">
                <img v-img style="width:105%" :src="url2" />
              </v-col>
              <!-- <v-col cols="8"></v-col> -->
            </v-row>
            <!-- <v-datetime-field
             :menu-props="{ top: true }"
             :date-props="{ outlined: true, label: 'Date' }"
             :error-messages="errors"
/> -->
          </v-container>
          <small class="ml-3">*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="same()"> Cancel </v-btn>
          <v-btn color="primary" @click="DonorIntormation()" :disabled="!formIsValid"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>

import Technicianservice from '@/service/Technicians.service'
import donorService from '@/service/Donor.service'
import EditDonorInformation from './EditDonorInformation.vue'
import moment from 'moment'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
const baseUrl = "https://api.ebsr.in/"
const userName = localData.user_name
const userId = localData.user_id
const userNumber = localData.user_number

import { api } from '@/config/config'


export default {
  components: {
    EditDonorInformation,
  },
  data() {
    return {
      nameee: [
        v => !!v,
        v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      readOnlyField: false,
      editItem: '',
      assign_lab_technician_id: '',
      newitem: '',
      assign_lab_technician_name: '',
      ConfirmModal: false,
      usertype: '',
      Statuss: ['Assigned', 'Unassigned'],
      Allforma: [{ name: '' }],
      btn: false,
      e1: 8,
      expanded: [],
      singleExpand: true,
      textFieldProps: {
        outlined: true,
        dense: true,
        'hide-details': true,
      },
      menu: false,
      modal: false,
      menu2: false,
      modal2: false,
      checked: true,
      listLoading: false,
      file: null,
      LinenSetInitials: null,
      ExcisionSetInitals: null,
      image: null,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      Allform: [],
      Allform1: [
        { value: "FormA", text: 'Form A' },
      ],
      Allform2: [
        { value: "FormA", text: 'Form A' },
        { value: "FormB", text: 'Form B' },
      ],
      formValue: '',
      EditUserRole: false,
      DeleteModal: false,
      editDonorinformation: false,
      selectedFile: '',
      thisItemId: '',
      timeout: 2000,
      headers: [
        { text: 'Donor Id', value: 'donor_id', align: ' d-none' },
        { text: 'Sub Donor Id', value: 'subId', align: ' d-none' },
        { text: 'Notification Id', value: 'form_id', align: ' d-none' },
        { text: 'Donor Name', value: 'donor_name', width: '150px' },

        // { text: "Donor's Age", value: 'age', width: '100px' },
        // { text: "Donor's Sex", value: 'sex', width: '100px' },
        { text: "Phone No.", value: 'phone_no', width: '100px' },

        { text: "Date of Assign", value: 'previous_assign_date', width: '150px'},

        { text: 'Assign To', value: 'previous_assign_lab_technician_name', width: '120px' },

        { text: "Date of Re-Assign", value: 'assign_lab_tech_date', width: '150px'},

        { text: 'Re-Assign To', value: 're_assign_lab_technician_name', width: '120px' },


        // { text: 'Action', value: 'Action', width: '70px', },
        // { text: 'OPERATE', value: 'operate', width: '500px', }
        // { text: "Date and Time of Donor's Death", value: "date_of_time_of_death",width: '200px'},
        // { text: "Date and Time of Donor's Relative's consent", value: "date_of_time_of_consent",width: '200px'},
        // { text: 'Date and Time of Excision', value: 'date_of_time_of_excision',width: '200px'},
        // { text: "Cause of Donor's Death", value: 'cause_of_death', width: '150px' },
        // { text: 'Received On', value: 'received_on', width: '150px' },
        // { text: 'Referral By', value: 'referral_name', width: '150px' },
        // { text: 'Referral Contact No.', value: 'referral_contact_number', width: '100px' },
        // { text: 'Date.', value: 'date',align: ' d-none' },
        // { text: 'Parent/Guardian Relation', value: 's_w_d_of',align: ' d-none' },
        // { text: 'Parent/Guardian Name', value: 'donor_parent_guardian_name',align: ' d-none' },
        // { text: 'ADDRESS', value: 'postal_address',align: ' d-none' },
        // { text: 'Relative relation with Donor', value: 'relation_with_donor',align: ' d-none' },
        // { text: 'User Type', value: 'category_type_of_the_requesting_person',align: ' d-none' },
        // { text: 'Temperature', value: 'temperature',align: ' d-none' },
        // { text: 'Received from (Name of nearest relation)', value: 'received_from',align: ' d-none' },
        // { text: 'Consignment Information', value: 'consignment_information',align: ' d-none' },
        // { text: 'Information about eyes received', value: 'information_about_eyes_received',align: ' d-none' },
        // { text: 'Right Eye', value: 'right_eye',align: ' d-none' },
        // { text: 'Left Eye', value: 'left_eye',align: ' d-none' },
        // { text: 'Condition of Eye Ball/Cornea at time of receipt',value: 'condition_of_eye_ball_cornea_at_time_of_receive',align: ' d-none' },
        // { text: 'Condition of Eye Ball/Cornea on Inspection', value: 'condition_of_eye_ball_cornea_at_time_of_inspection',align: ' d-none' },
        // { text: 'any other information', value: 'any_other_information',align: ' d-none' },
        // { text: 'Excision set date and time of sterilization',value: 'excision_set_date_and_time_of_sterilization',align: ' d-none' },
        // { text: 'Excision set date and time of expiry', value: 'excision_set_date_and_time_of_expiry', align: ' d-none' },
        // { text: 'Excision set initals', value: 'excision_set_initials',align: ' d-none' },
        // { text: 'Linen set date and time of sterilization',value: 'linen_set_date_and_time_of_sterilization',align: ' d-none' },
        // { text: 'Linen set date and time of expiry', value: 'linen_set_date_and_time_of_expiry',align: ' d-none' },
        // { text: 'Linen set initials', value: 'linen_set_initials',align: ' d-none' },
        // { text: 'Permission Certificate Url', value: 'permission_certificate',align: ' d-none' },


      ],
      donorList: [],
      permissionData: [],
      filter: {
        donor_name: '',
        phone_no: '',
        received_on: '',
        referral_name: '',
        referral_contact_number: '',
        lab_technician_nameFilter: '',
      },

      DonorInformationList: {
        donor_name: '',
        s_w_d_of: '',
        donor_parent_guardian_name: '',
        age: '',
        sex: '',
        postal_address: '',
        phone_no: '',
        donor_relative_name: '',
        relation_with_donor: '',
        referral_name: userName,
        referral_contact_number: userNumber,
        category_type_of_the_requesting_person: 'Eye Bank Staff',
        date_of_time_of_death: '',
        date_of_time_of_consent: '',
        date_of_time_of_excision: '',
        lab_technician_name: '',
        cause_of_death: '',
        temperature: '',
        consignment_information: '',
        received_on: '',
        right_eye: false,
        condition_of_right_eye_ball_cornea_at_time_of_receive: '',
        condition_of_right_eye_ball_cornea_at_time_of_inspection: '',
        left_eye: false,
        condition_of_left_eye_ball_cornea_at_time_of_receive: '',
        condition_of_left_eye_ball_cornea_at_time_of_inspection: '',
        any_other_information: '',
        excision_set_date_and_time_of_sterilization: '',
        excision_set_date_and_time_of_expiry: '',
        excision_set_initials: '',
        linen_set_date_and_time_of_sterilization: '',
        linen_set_date_and_time_of_expiry: '',
        linen_set_initials: '',
        user_id: '',
        referral_by_id: userId,
        photo: '',
        ExcisionSetInitals: '',
        LinenSetInitials: '',
        file: ''
      },
      addDonorModal: false,
      roleList: ['Son', 'Wife', 'Daughter'],
      Sex: ['Male', 'Female'],
    }

  },

  watch: {
    options: {
      handler() {
        this.getdonorList()
      },
      deep: true,
    },
  },

  computed: {


    url() {
      if (!this.LinenSetInitials) return;
      return URL.createObjectURL(this.LinenSetInitials);
    },

    url1() {
      if (!this.ExcisionSetInitals) return;
      return URL.createObjectURL(this.ExcisionSetInitals);
    },

    url2() {
      if (!this.file) return;
      return URL.createObjectURL(this.file);
    },

    formIsValid() {
      return (
        this.DonorInformationList.donor_name &&
        this.DonorInformationList.s_w_d_of &&
        this.DonorInformationList.donor_parent_guardian_name &&
        this.DonorInformationList.age &&
        this.DonorInformationList.sex &&
        this.DonorInformationList.postal_address &&
        this.DonorInformationList.phone_no &&
        this.DonorInformationList.donor_relative_name &&
        this.DonorInformationList.relation_with_donor &&
        this.DonorInformationList.referral_name &&
        this.DonorInformationList.referral_contact_number &&
        this.DonorInformationList.category_type_of_the_requesting_person &&
        this.DonorInformationList.date_of_time_of_death &&
        this.DonorInformationList.date_of_time_of_consent &&
        this.DonorInformationList.date_of_time_of_excision &&
        this.DonorInformationList.cause_of_death &&
        this.DonorInformationList.temperature &&
        // this.DonorInformationList.consignment_information &&
        this.DonorInformationList.received_on &&
        // this.DonorInformationList.right_eye &&
        // this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_receive &&
        // this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_inspection &&
        // this.DonorInformationList.left_eye &&
        // this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_receive &&
        // this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_inspection &&
        // this.DonorInformationList.any_other_information &&
        this.DonorInformationList.excision_set_date_and_time_of_sterilization &&
        this.DonorInformationList.excision_set_date_and_time_of_expiry &&
        this.DonorInformationList.linen_set_date_and_time_of_sterilization &&
        this.DonorInformationList.linen_set_date_and_time_of_expiry &&
        this.DonorInformationList.excision_set_initials &&
        this.DonorInformationList.linen_set_initials &&
        this.DonorInformationList.photo
      )
    },

    // createdDate: {
    //   get() {
    //     return moment(this.DonorInformationList.date).format('YYYY-MM-DD HH:mm:ss')
    //   },
    // },

    deathDate: {
      get() {
        return this.DonorInformationList.date_of_time_of_death ? moment(this.DonorInformationList.date_of_time_of_death).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.DonorInformationList, 'date_of_time_of_death', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    consentDate: {
      get() {
        return this.DonorInformationList.date_of_time_of_consent ? moment(this.DonorInformationList.date_of_time_of_consent).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.DonorInformationList, 'date_of_time_of_consent', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    excisionDate: {
      get() {
        return this.DonorInformationList.date_of_time_of_excision ? moment(this.DonorInformationList.date_of_time_of_excision).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.DonorInformationList, 'date_of_time_of_excision', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    receivedDate: {
      get() {
        return this.DonorInformationList.received_on ? moment(this.DonorInformationList.received_on).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.DonorInformationList, 'received_on', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    excisionSterilizationDate: {
      get() {
        return this.DonorInformationList.excision_set_date_and_time_of_sterilization ? moment(this.DonorInformationList.excision_set_date_and_time_of_sterilization).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.DonorInformationList, 'excision_set_date_and_time_of_sterilization', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    excisionExpiryDate: {
      get() {
        return this.DonorInformationList.excision_set_date_and_time_of_expiry ? moment(this.DonorInformationList.excision_set_date_and_time_of_expiry).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.DonorInformationList, 'excision_set_date_and_time_of_expiry', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    linenSterilizationDate: {
      get() {
        return this.DonorInformationList.linen_set_date_and_time_of_sterilization ? moment(this.DonorInformationList.linen_set_date_and_time_of_sterilization).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.DonorInformationList, 'linen_set_date_and_time_of_sterilization', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    linenExpiryDate: {
      get() {
        return this.DonorInformationList.linen_set_date_and_time_of_expiry ? moment(this.DonorInformationList.linen_set_date_and_time_of_expiry).format('YYYY-MM-DD HH:mm:ss') : ''
      },
      set(value) {
        this.$set(this.DonorInformationList, 'linen_set_date_and_time_of_expiry', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },
    
  },

  async mounted() {
    this.getdonorList()
    this.popup()
    this.getTechnicians()
  },

  methods: {

    convertDeathDate(data){
      return (moment.utc(data).format("DD-MM-YYYY HH:mm"))
    },
    convertReceivedDate(data){
      return (moment.utc(data).format("DD-MM-YYYY HH:mm"))
    },
    previousAssignDate(data){
      return (moment.utc(data).format("DD-MM-YYYY HH:mm"))
    },
    assignLabTechDate(data){
      return (moment.utc(data).format("DD-MM-YYYY HH:mm"))
    },

    formRoute(item) {

      this.ConfirmModal = true
      this.newitem = item
      const donoridd = this.Allforma.filter((d) => d.name === item.assign_lab_technician_name)
      this.assign_lab_technician_id = donoridd[0].id
      this.read(item)
    },
    // read(item){
    //     if(item.assign_lab_technician_name != null){
    //       this.readOnlyField = true
    //     } 
    // },
    async confirmTONext(item) {
      const data = {
        donor_id: item.donor_id,
        assign_lab_technician_id: this.assign_lab_technician_id,
        assign_lab_technician_name: item.assign_lab_technician_name
      }
      try {
        const response = await api.post(`donorNotification/assign_lab_technician`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        })
        if (response.status == 200) {
          this.ConfirmModal = false

          this.snackbarText = response.data.msg
        } else {
          this.snackbarText = response.data.msg
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async getTechnicians() {
      this.listLoading = true
      const service = new Technicianservice()
      let response = await service.TechniciansList()
      this.Allforma = response.data
      // console.log("hekjklhkfd",this.Allforma)
      this.usertype = localData.userDesignation
    },
    same() {
      this.addDonorModal = false
      window.location.reload()
      // this.DonorInformationList.donor_name =''
      //   this.DonorInformationList.s_w_d_of =''
      //   this.DonorInformationList.donor_parent_guardian_name =''
      //   this.DonorInformationList.age =''
      //   this.DonorInformationList.sex =''
      //   this.DonorInformationList.postal_address =''
      //   this.DonorInformationList.phone_no =''
      //   this.DonorInformationList.donor_relative_name =''
      //   this.DonorInformationList.relation_with_donor =''
      //   this.DonorInformationList.referral_name =''
      //   this.DonorInformationList.referral_contact_number =''
      //   this.DonorInformationList.category_type_of_the_requesting_person =''
      //   this.DonorInformationList.date_of_time_of_death =''
      //   this.DonorInformationList.date_of_time_of_consent =''
      //   this.DonorInformationList.date_of_time_of_excision =''
      //   this.DonorInformationList.cause_of_death =''
      //   this.DonorInformationList.temperature =''
      //   this.DonorInformationList.consignment_information =''
      //   this.DonorInformationList.received_on =''
      //   this.DonorInformationList.right_eye =''
      //   this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_receive =''
      //   this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_inspection =''
      //   this.DonorInformationList.left_eye =''
      //   this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_receive =''
      //   this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_inspection =''
      //   this.DonorInformationList.any_other_information =''
      //   this.DonorInformationList.excision_set_date_and_time_of_sterilization =''
      //   this.DonorInformationList.excision_set_date_and_time_of_expiry =''
      //   this.DonorInformationList.linen_set_date_and_time_of_sterilization =null
      //   this.linenExpiryDate = ''
      //   this.ExcisionSetInitals =null
      //   this.LinenSetInitials =null
      //   this.file =null
    },
    restLE() {
      if (this.DonorInformationList.left_eye === true) {
        this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_receive = '',
          this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_inspection = ''
      }
    },
    restRE() {
      if (this.DonorInformationList.right_eye != 'RE') {
        this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_receive = '',
          this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_inspection = ''
      }
    },

    onlyNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9) {
        $event.preventDefault();
      }
    },

    onlyAge($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2) {
        $event.preventDefault();
      }
    },

    async checkEyeBankStaff() {
      this.toggleReadonly(event)
      if (this.DonorInformationList.category_type_of_the_requesting_person == 'Hospital Staff') {
        this.DonorInformationList.referral_name = ""
        this.DonorInformationList.referral_contact_number = ""
        this.DonorInformationList.referral_by_id = ""
      } else if (this.DonorInformationList.category_type_of_the_requesting_person == 'Volunteer') {
        this.DonorInformationList.referral_name = ""
        this.DonorInformationList.referral_contact_number = ""
        this.DonorInformationList.referral_by_id = ""
      } else {
        this.DonorInformationList.referral_name = userName
        this.DonorInformationList.referral_contact_number = userNumber
        this.DonorInformationList.referral_by_id = userId
      }
    },

    async toggleReadonly(event) {
      event.preventDefault()
      if (this.DonorInformationList.category_type_of_the_requesting_person == 'Eye Bank Staff') {
        event.target.setAttribute('readonly', 'readonly')
      } else {
        event.target.removeAttribute('readonly')
      }
    },
    async handleForms(value) {
      this.formValue = value
    },

    async formsRoute(item) {
      if (this.formValue == "FormA") {
        this.$router.push({ name: 'Form_A', params: { donor_id: item.donor_id } });
      } if (this.formValue == "FormB") {
        this.$router.push({ name: 'Form_B', params: { donor_id: item.donor_id } });
      } if (this.formValue == "FormCpart") {
        this.$router.push({ name: 'Form_Cpart', params: { donor_id: item.donor_id } });
      }
      if (this.formValue == "donorInformationData") {

        this.$router.push({ name: 'donorss', params: { donor_id: item.donor_id } });
        //  this.popup()
        window.location.reload()
      }
      if (this.formValue == "FormRetrieval") {
        this.$router.push({ name: 'Retrieval_Form', params: { donor_id: item.donor_id } });
      }
      if (this.formValue == "FormC") {
        this.$router.push({ name: 'Form_C', params: { donor_id: item.donor_id } });
      }
      if (this.formValue == "Hemodilution") {
        this.$router.push({ name: 'Hemodilution', params: { donor_id: item.donor_id } });
      } else if (this.formValue == "SlitLamp") {
        this.$router.push({ name: 'SlitLamp', params: { donor_id: item.donor_id } });
      } else if (this.formValue == "Recipient") {
        this.$router.push({ name: 'Recipient', params: { donor_id: item.donor_id } });
      } else if (this.formValue == "TissueDetail") {
        this.$router.push({ name: 'TissueDetail', params: { donor_id: item.donor_id } });
      } else if (this.formValue == "AdverseReaction") {
        this.$router.push({ name: 'AdverseReaction', params: { donor_id: item.donor_id } });
      } else if (this.formValue == "BillOfLading") {
        this.$router.push({ name: 'BillOfLading', params: { donor_id: item.donor_id } });
      }
      else if (this.formValue == "TissueDisposition") {
        this.$router.push({ name: 'TissueDisposition', params: { donor_id: item.donor_id } });
      }

    },


    editInformation(e) {
      this.editDonorinformation = true
      this.editItem = e
    },

    async popup() {
      const DonorId = this.$route.params.donor_id;
      const service = new donorService()
      let response = await service.SingalDonor(DonorId)
      // console.log("My Donoro",response)
      if (DonorId === undefined) {
        this.editDonorinformation = false
        this.editItem = response
      } else if (DonorId != '') {
        this.editDonorinformation = true
        this.editItem = response
      }

    },

    deleteDonorInformations(e) {
      this.DeleteModal = true
      this.thisItemId = e.donor_id
    },

    async getdonorList() {
      this.listLoading = true
      const service = new donorService()
      let response = await service.reAssignDonorList()
      if (response) {
        if(response.data){
          response.data.forEach((item, i) => {
            item.subId = i + 1;
          })
          this.donorList = response.data
        }
        // this.totallist = this.donorList.length
        this.listLoading = false
        this.permissionData = response.permissions
        var FormAdata = (this.permissionData)[1]
        var FormBdata = (this.permissionData)[2]
        var FormCdata = (this.permissionData)[3]
        var donorInformationData = (this.permissionData)[4]
        var eyedonormedicalparticularsdata = (this.permissionData)[5]
        var hemodilutionassessmentdata = (this.permissionData)[6]
        var tissueevaluationData = (this.permissionData)[7]
        var tissuedetailformdata = (this.permissionData)[8]
        var tissueoffereddata = (this.permissionData)[9]
        var billofladingdata = (this.permissionData)[10]
        var adversereactionreportdata = (this.permissionData)[11]
        var recipientinformationformdata = (this.permissionData)[12]
        var tissuedispositiondata = (this.permissionData)[13]


        if (FormAdata.view == "1" && FormAdata.edit == "1" || FormAdata.view == "1" && FormAdata.edit == "0" || FormAdata.view == "1" && FormAdata.edit == null) {
          this.Allform.push({ value: "FormA", text: 'Form A' });
        } if (FormBdata.view == "1" && FormBdata.edit == "1" || FormBdata.view == "1" && FormBdata.edit == "0" || FormBdata.view == "1" && FormBdata.edit == null) {
          this.Allform.push({ value: "FormB", text: 'Form B' });
        } if (FormCdata.view == "1" && FormCdata.edit == "1" || FormCdata.view == "1" && FormCdata.edit == "0" || FormCdata.view == "1" && FormCdata.edit == null) {
          this.Allform.push({ value: "FormCpart", text: 'Form C - Laboratory Information' });
        }
        if (donorInformationData.view == "1" && donorInformationData.edit == "1" || donorInformationData.view == "1" && donorInformationData.edit == "0" || donorInformationData.view == "1" && donorInformationData.edit == null) {
          this.Allform.push({ value: "donorInformationData", text: 'Donor Information with Consent' });
        }
        if (eyedonormedicalparticularsdata.view == "1" && eyedonormedicalparticularsdata.edit == "1" || eyedonormedicalparticularsdata.view == "1" && eyedonormedicalparticularsdata.edit == "0" || eyedonormedicalparticularsdata.view == "1" && eyedonormedicalparticularsdata.edit == null) {
          this.Allform.push({ value: "FormRetrieval", text: 'Eye Donor Medical Particulars' });

        }
        if (FormCdata.view == "1" && FormCdata.edit == "1" || FormCdata.view == "1" && FormCdata.edit == "0" || FormCdata.view == "1" && FormCdata.edit == null) {
          this.Allform.push({ value: "FormC", text: 'Form C' });
        }
        if (hemodilutionassessmentdata.view == "1" && hemodilutionassessmentdata.edit == "1" || hemodilutionassessmentdata.view == "1" && hemodilutionassessmentdata.edit == "0" || hemodilutionassessmentdata.view == "1" && hemodilutionassessmentdata.edit == null) {
          this.Allform.push({ value: "Hemodilution", text: 'Hemodilution Assessment' });
        } if (tissueevaluationData.view == "1" && tissueevaluationData.edit == "1" || tissueevaluationData.view == "1" && tissueevaluationData.edit == "0" || tissueevaluationData.view == "1" && tissueevaluationData.edit == null) {
          this.Allform.push({ value: "SlitLamp", text: 'Tissue Evaluation' });
        } if (tissuedetailformdata.view == "1" && tissuedetailformdata.edit == "1" || tissuedetailformdata.view == "1" && tissuedetailformdata.edit == "0" || tissuedetailformdata.view == "1" && tissuedetailformdata.edit == null) {
          this.Allform.push({ value: "TissueDetail", text: 'Tissue Detail' });
        } if (billofladingdata.view == "1" && billofladingdata.edit == "1" || billofladingdata.view == "1" && billofladingdata.edit == "0" || billofladingdata.view == "1" && billofladingdata.edit == null) {
          this.Allform.push({ value: "BillOfLading", text: 'Bill of Lading' });
        } if (adversereactionreportdata.view == "1" && adversereactionreportdata.edit == "1" || adversereactionreportdata.view == "1" && adversereactionreportdata.edit == "0" || adversereactionreportdata.view == "1" && adversereactionreportdata.edit == null) {
          this.Allform.push({ value: "AdverseReaction", text: 'Adverse Reaction ' });
        } if (recipientinformationformdata.view == "1" && recipientinformationformdata.edit == "1" || recipientinformationformdata.view == "1" && recipientinformationformdata.edit == "0" || recipientinformationformdata.view == "1" && recipientinformationformdata.edit == null) {
          this.Allform.push({ value: "Recipient", text: 'Recipient Information' });
        } if (tissuedispositiondata.view == "1" && tissuedispositiondata.edit == "1" || tissuedispositiondata.view == "1" && tissuedispositiondata.edit == "0" || tissuedispositiondata.view == "1" && tissuedispositiondata.edit == null) {
          this.Allform.push({ value: "TissueDisposition", text: 'Tissue Disposition' });
        }
        this.convertDeathDate()
        this.convertReceivedDate()
        this.previousAssignDate()
        this.assignLabTechDate()
      } else {
        this.donorList = []
        this.totallist = 0
      }
      this.listLoading = false
    },

    async getDonorRefresh() {
      this.filter.donor_name = ""
      this.filter.phone_no = ""
      this.filter.received_on = ""
      this.filter.referral_name = ""
      this.filter.referral_contact_number = ""
      this.filter.lab_technician_nameFilter = ""
      this.getdonorList()
    },

    onFileChange() {
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.onload = e => {
        this.DonorInformationList.photo = e.target.result
      }
    },
    onFileChangeExcision() {
      const reader1 = new FileReader()
      reader1.readAsDataURL(this.ExcisionSetInitals)
      reader1.onload = e => {
        this.DonorInformationList.excision_set_initials = e.target.result
      }
    },
    onFileChangeLinen() {
      const reader2 = new FileReader()
      reader2.readAsDataURL(this.LinenSetInitials)
      reader2.onload = e => {
        this.DonorInformationList.linen_set_initials = e.target.result
      }
    },

    async DonorIntormation() {
      const data = {
        donor_name: this.DonorInformationList.donor_name,
        s_w_d_of: this.DonorInformationList.s_w_d_of,
        donor_parent_guardian_name: this.DonorInformationList.donor_parent_guardian_name,
        age: this.DonorInformationList.age,
        sex: this.DonorInformationList.sex,
        postal_address: this.DonorInformationList.postal_address,
        phone_no: this.DonorInformationList.phone_no,
        donor_relative_name: this.DonorInformationList.donor_relative_name,
        relation_with_donor: this.DonorInformationList.relation_with_donor,
        referral_name: this.DonorInformationList.referral_name,
        referral_contact_number: this.DonorInformationList.referral_contact_number,
        category_type_of_the_requesting_person: this.DonorInformationList.category_type_of_the_requesting_person,
        date_of_time_of_death: this.DonorInformationList.date_of_time_of_death,
        date_of_time_of_consent: this.DonorInformationList.date_of_time_of_consent,
        date_of_time_of_excision: this.DonorInformationList.date_of_time_of_excision,
        cause_of_death: this.DonorInformationList.cause_of_death,
        temperature: this.DonorInformationList.temperature,
        consignment_information: this.DonorInformationList.consignment_information,
        received_on: this.DonorInformationList.received_on,
        right_eye: this.DonorInformationList.right_eye,
        condition_of_right_eye_ball_cornea_at_time_of_receive: this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_receive,
        condition_of_right_eye_ball_cornea_at_time_of_inspection: this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_inspection,
        left_eye: this.DonorInformationList.left_eye,
        condition_of_left_eye_ball_cornea_at_time_of_receive: this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_receive,
        condition_of_left_eye_ball_cornea_at_time_of_inspection: this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_inspection,
        any_other_information: this.DonorInformationList.any_other_information,
        excision_set_date_and_time_of_sterilization: this.DonorInformationList.excision_set_date_and_time_of_sterilization,
        excision_set_date_and_time_of_expiry: this.DonorInformationList.excision_set_date_and_time_of_expiry,
        excision_set_initials: this.DonorInformationList.excision_set_initials,
        linen_set_date_and_time_of_sterilization: this.DonorInformationList.linen_set_date_and_time_of_sterilization,
        linen_set_date_and_time_of_expiry: this.DonorInformationList.linen_set_date_and_time_of_expiry,
        linen_set_initials: this.DonorInformationList.linen_set_initials,
        form_type: "2",
        user_id: userId,
        referral_by_id: this.DonorInformationList.referral_by_id,
        permission_certificate: this.DonorInformationList.photo,
      }
      try {
        const response = await api.post(`donorNotification/add_donor_information`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        })
        if (response.data.status == 200) {
          this.addDonorModal = false
          this.snackbarText = response.data.message
          this.getdonorList()
          this.DonorInformationList.donor_name = ''
          this.DonorInformationList.s_w_d_of = ''
          this.DonorInformationList.donor_parent_guardian_name = ''
          this.DonorInformationList.age = ''
          this.DonorInformationList.sex = ''
          this.DonorInformationList.postal_address = ''
          this.DonorInformationList.phone_no = ''
          this.DonorInformationList.donor_relative_name = ''
          this.DonorInformationList.relation_with_donor = ''
          this.DonorInformationList.referral_name = ''
          this.DonorInformationList.referral_contact_number = ''
          this.DonorInformationList.category_type_of_the_requesting_person = ''
          this.deathDate = ''
          this.consentDate = ''
          this.excisionDate = ''
          this.DonorInformationList.cause_of_death = ''
          this.DonorInformationList.temperature = ''
          this.DonorInformationList.consignment_information = ''
          this.receivedDate = ''
          this.DonorInformationList.right_eye = ''
          this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_receive = ''
          this.DonorInformationList.condition_of_right_eye_ball_cornea_at_time_of_inspection = ''
          this.DonorInformationList.left_eye = ''
          this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_receive = ''
          this.DonorInformationList.condition_of_left_eye_ball_cornea_at_time_of_inspection = ''
          this.DonorInformationList.any_other_information = ''
          this.excisionSterilizationDate = ''
          this.excisionExpiryDate = ''
          this.ExcisionSetInitals = null
          this.linenSterilizationDate = ''
          this.linenExpiryDate = ''
          this.LinenSetInitials = null,
            this.file = null,
            this.url1 = null,
            this.url = null

        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async deleteDonorInformation(item) {
      const data = {
        id: item.id,
        donor_id: item,
      }
      try {
        const response = await api.post(`donorNotification/delete`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`
          },
        })
        if (response.data.status == 200) {
          this.snackbarText = response.data.message
          this.getdonorList()
          this.DeleteModal = false
        } else {
          this.snackbarText = response.data.message
        }
        this.snackbar = true
      } catch (e) {
        console.log(e)
      }
    },

    async DonorSearchList() {
      if (this.filter.donor_name || this.filter.phone_no || this.filter.received_on || this.filter.referral_name || this.filter.referral_contact_number || this.filter.lab_technician_nameFilter) {
        this.listLoading = true
        try {
          const data = {
            donor_name: this.filter.donor_name,
            phone_no: this.filter.phone_no,
            received_on: this.filter.received_on,
            referral_by: this.filter.referral_name,
            referral_contact_no: this.filter.referral_contact_number,
            lab_technician_name: this.filter.lab_technician_nameFilter,
          }
          const service = new donorService()
          const response = await service.reAssignDonorSearchList(data)
          if(response.data){
            response.data.forEach((item, i) => {
              item.subId = i + 1;
            })
            this.donorList = response.data
          }else{
            this.donorList = []
          }
          this.listLoading = false
        } catch (e) {
          console.log(e)
        }
      }else {
        this.snackbarText = "Kindly Enter The Keyword"
        this.snackbar = true
        return
      }
    },

  },
}
</script>

